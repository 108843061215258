<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <svg :aria-label="t('label.refreshLink')" width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99996 11.3333C4.51107 11.3333 3.24996 10.8166 2.21663 9.78329C1.18329 8.74996 0.666626 7.48885 0.666626 5.99996C0.666626 4.51107 1.18329 3.24996 2.21663 2.21663C3.24996 1.18329 4.51107 0.666626 5.99996 0.666626C6.76663 0.666626 7.49996 0.824959 8.19996 1.14163C8.89996 1.45829 9.49996 1.91107 9.99996 2.49996V0.666626H11.3333V5.33329H6.66663V3.99996H9.46663C9.11107 3.37774 8.62496 2.88885 8.00829 2.53329C7.39163 2.17774 6.72218 1.99996 5.99996 1.99996C4.88885 1.99996 3.9444 2.38885 3.16663 3.16663C2.38885 3.9444 1.99996 4.88885 1.99996 5.99996C1.99996 7.11107 2.38885 8.05552 3.16663 8.83329C3.9444 9.61107 4.88885 9.99996 5.99996 9.99996C6.85552 9.99996 7.62774 9.75552 8.31663 9.26663C9.00552 8.77774 9.48885 8.13329 9.76663 7.33329H11.1666C10.8555 8.51107 10.2222 9.47218 9.26663 10.2166C8.31107 10.9611 7.22218 11.3333 5.99996 11.3333Z" fill="currentColor"/>
  </svg>

</template>

<style scoped>
</style>
