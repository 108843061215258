<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <svg :aria-label="t('label.copyLink')" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="layer1">
      <path id="rect560" opacity="0.2" d="M13.0002 5.00014V12.0001C13.0002 13.6621 11.6622 15.0001 10.0002 15.0001H7.00024V16.0001C7.00024 17.6621 8.33825 19.0001 10.0002 19.0001H16.0002C17.6622 19.0001 19.0002 17.6621 19.0002 16.0001V8.00014C19.0002 6.33814 17.6622 5.00014 16.0002 5.00014H13.0002Z" fill="currentColor"/>
      <path id="rect292" d="M4 0C1.8013 0 0 1.8013 0 4V12C0 14.1987 1.8013 16 4 16H6C6 18.1987 7.80131 20 10 20H16C18.1987 20 20 18.1987 20 16V8C20 5.8013 18.1987 4 16 4H14C14 1.8013 12.1987 0 10 0H4ZM4 2H10C11.1253 2 12 2.8747 12 4V12C12 13.1253 11.1253 14 10 14H4C2.87471 14 2 13.1253 2 12V4C2 2.8747 2.87471 2 4 2ZM5 4C4.446 4 4 4.446 4 5C4 5.554 4.446 6 5 6H9C9.554 6 10 5.554 10 5C10 4.446 9.554 4 9 4H5ZM14 6H16C17.1253 6 18 6.8747 18 8V16C18 17.1253 17.1253 18 16 18H10C8.87471 18 8 17.1253 8 16H10C12.1987 16 14 14.1987 14 12V6ZM5 8C4.446 8 4 8.446 4 9C4 9.554 4.446 10 5 10H9C9.554 10 10 9.554 10 9C10 8.446 9.554 8 9 8H5Z" fill="currentColor"/>
    </g>
  </svg>
</template>

<style scoped>
</style>
